export const blacklist = [
    'wir','ich','so','ist','das','an.','ja.','ja','ja,','die','als','der','mal','ab','was'

]
export const SPECIAL_PROJECTID ='22319d3a-490f-4abb-b8fe-a0e61700d21f'
//'42c393a7-70f9-4b94-8a1d-21d3c1893ad0'
export const ERRORS = [
    {"code" : "ERR_BAD_REQUEST", "message": "project not found , please check the projectId and try again !"},
    {"code" : "ERR_NETWORK", "message": "no internet , make sure you have internet and try again !"},
]

//comment