export class TimerService {
  minutes = 0;
  seconds = 0;
  end = false;
  timerInterval = null;
  playOutroVideo = false;
  firstTranscript = "";
  tempTranscript = "";
  timeToReload = null;

  start(outroTimeOut) {
    this.end = false;

    this.timerInterval = setInterval(() => {
      if (this.end) {
        // stop timer
        this.stopTimer();
        return;
      }
      if (this.seconds > 59) {
        this.minutes = this.minutes + 1; //because outrotimeout´s value is in minutes ,comparisons here will be in minutes 
        this.seconds = 0;
      } else if (
        this.minutes === outroTimeOut &&
        this.firstTranscript === this.tempTranscript
      ) {
        // outroTimeout  reached and transcript didnt change => user said something or not and stayed silent until outrotimeout reached.
        this.shouldPayOutro();
      } else if (
        this.minutes < outroTimeOut &&
        this.firstTranscript !== "" &&
        this.firstTranscript === this.tempTranscript
      ) {
        // outro timeout not reached yet but user keep talking , so reset everything to 0 ,
       
        this.justReset();
      } else if (this.firstTranscript !== this.tempTranscript) {
         //reset  everytime user say a word different to previous one.
        this.justReset();
      }
      this.seconds = this.seconds + 1;
    }, 1000);
  }

  stopTimer() {
    this.minutes = 0;
    this.seconds = 0;
    this.end = true;

    this.playOutroVideo = false;
    clearInterval(this.timerInterval);

    this.firstTranscript = "";
    this.tempTranscript = "";
  }
  justReset() {
    this.minutes = 0;
    this.seconds = 0;
    this.firstTranscript = "";
    this.tempTranscript = "";
  }
  shouldPayOutro() {
    this.playOutroVideo = true;
    this.minutes = 0;
    this.seconds = 0;
    clearInterval(this.timerInterval);
  }
}
