import React from 'react'

const NoData = () => {
  return (
    <div className=" w-screen h-screen  items-center text-center bg-white ">
    <h1 >
        no data , please add an ProjectId
    </h1>
</div>
  

  )
}

export default NoData