import { useEffect, useState } from 'react'

const HeartBeatComp = ({interval = 5000 , timeout = 10000 }) => {
  const [lastCallTime, setLastCallTime] = useState(Date.now())
  useEffect(() => {

    const updateLastCall = () => {
      setLastCallTime(Date.now())
    }
    const isAppResponding = () => {
      const currenTime = Date.now()
      //console.log(lastCallTime, currenTime, currenTime - lastCallTime)
      if(currenTime - lastCallTime > timeout){
        window.location.reload()

      }
    }
    const updateLastCallInterval = setInterval(updateLastCall, interval)
    const isAppRespondingInterval = setInterval(isAppResponding, interval)
    return () => {
      clearInterval(updateLastCallInterval);
      clearInterval(isAppRespondingInterval)
    }

  }, [interval, timeout, lastCallTime])
  return  null
}

export default HeartBeatComp