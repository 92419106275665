"use client";
import "regenerator-runtime/runtime";

import React, { createContext, useContext, useState, useEffect } from "react";
import { blacklist } from "../api/constants/constants";
import { LlmService } from "../services/llmService";
import { TimerService } from "../services/timerService";
import VideoService from "../services/videoService";
const llmService = new LlmService();
const timerService = new TimerService();
const videoService = new VideoService();
let transcriptList = [];
let openedSockets = [];
let mediaRecords = [];
let isListening = false;


const AppContext = createContext();

export function StateContext({ children }) {
  const [loopVideos, setLoopVideos] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [outroVideos, setOutroVideos] = useState([]);

  const [introVideos, setIntroVideos] = useState([]);
  const [repeatVideos, setRepeatVideos] = useState([]);

  const [currentVideo, setCurrentVideo] = useState({});
  const [defaultVideo, setDefaultVideo] = useState({});
  const [keyIndex, setKeyIndex] = useState(0);
  const [introVideoIndex, setIntroVideoIndex] = useState(0);

  const [startPlayingVideos, setStartPlayingVideos] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState("");
  const [isMicGettingInitialise, setIsMicGettingInitialise] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [savedTranscript, setSavedTranscript] = useState("");
  const [llmModelName, setLlmModelName] = useState("");
  const [outroTimeOut, setOutroTimeOut] = useState(null);
  const [introVideoIsPlaying, setIntroVideoIsPlaying] = useState(false);
  const [countNotFoundedVideos, setCountNotFoundedVideos] = useState(0);
  const [areAllVideosDownloaded, setAreAllVideosDowloaded] = useState(true);
  const [isChoosenVideoPlaying, setIsChoosenVideoPlaying] = useState(false);
  const [canListen, setCanListen] = useState(true);
  const [noDataInConsole, setNoDataInConsole] = useState(true);
  const [filterVideos, setFilterVideos] = useState([]);

  const [multiplesProjectLanguages, setMultiplesProjectLanguages] = useState([
    "de",
    "en",
  ]);
  const [multiplesTranscript, setMultiplesTranscript] = useState([]);
  const [multiplesSockets, setMultiplesSockets] = useState([]);
  const [multiplesMediaRecords, setMultiplesMediarecords] = useState([]);
  const [horizontalAdjustment, setHorizontalAdjustment] = useState(0);
  const [verticalAdjustment, setVerticalAdjustment] = useState(0);

  const [hasInternet, setHasInternet] = useState(true);
  const [deepsetFilter, setDeepsetFilter] = useState("");

  function handleOutroTimeout(data) {
    if (data.outroTimeout && data.outroTimeout > 60) {
      let minutes = data.outroTimeout / 60;

      setOutroTimeOut(minutes);
    } else {
      setOutroTimeOut(5);
    }
  }
  function handleCanListen(val) {
    setCanListen(val);
  }
  function isChoosenVideoFinishingPlaying() {
    setSavedTranscript("");
    setFinalTranscript("");
    setIsChoosenVideoPlaying(false);
  }
  function retrieveOrDowloadVideos(videos, download) {
    let count = 0;
    if (!download) {
      setAllVideos(videos);
      setAreAllVideosDowloaded(false);
      return;
    }
    videos.forEach((obj) => {
      videoService.prefetch_file(
        obj.videoUrl,
        (blob_url) => {
          obj.videoUrl = blob_url;
          count++;
          if (count === videos.length) {
            setAreAllVideosDowloaded(false);
            if (!noDataInConsole) {
              console.log("complete");
            }

            return true;
          }
        },
        (progress) => {},
        () => {}
      );
    });
  }
  function handleDataInConsole() {
    setNoDataInConsole(false);
  }
  const initDatas = (data, projectId, llmModelName) => {
    setProjectId(projectId);
    handleOutroTimeout(data);
    setHorizontalAndVerticalAdjustment(data);
    let deos = data.videos;
    var resultLoopVideos = deos.filter(function (element) {
      return element.isLoopVideo === true;
    });
    var resultIntroVideos = deos.filter(function (element) {
      return element.keywords.toLowerCase().includes("intro:");
    });
    var resultRepeatVideos = deos.filter(function (element) {
      return element.keywords.toLowerCase().includes("repeat:");
    });
    var resultOutroVideos = data.videos.filter(function (element) {
      return element.keywords.toLowerCase().includes("outro:");
    });
    var resultFilterVideos = data.videos.filter(function (element) {
      return element.keywords.toLowerCase().includes("filter:");
    });
    setLlmModelName(llmModelName);
    setAllVideos(deos);

    setLoopVideos(resultLoopVideos);
    setRepeatVideos(resultRepeatVideos);
    setOutroVideos(resultOutroVideos);
    setIntroVideos(resultIntroVideos);
    setFilterVideos(resultFilterVideos);
    setDefaultVideo(resultLoopVideos[0]);
    if (data.language !== null && data.language !== "") {
      if (data.language.includes(";")) {
        setMultiplesProjectLanguages(data.language.split(";"));
      } else {
        setMultiplesProjectLanguages([data.language]);
      }
    }
  };

  const handleCurrentVideo = (video) => {
    setCurrentVideo(video);
    if (!video.isLoopVideo) {
      setIsChoosenVideoPlaying(true);
    }
  };
  const setHorizontalAndVerticalAdjustment = (data) => {
    if (data.horizontalAdjustment && horizontalAdjustment === 0) {
      setHorizontalAdjustment(data.horizontalAdjustment);
    }
    if (data.verticalAdjustment && verticalAdjustment === 0) {
      setVerticalAdjustment(data.verticalAdjustment);
    }
  };
  const stopMicrophone = () => {
    isListening = false;
    for (let mdr of multiplesMediaRecords) {
      if (mdr && mdr.state === "recording") {
        mdr.stop();
        mdr.stream.getTracks().forEach((track) => track.stop());
        timerService.stopTimer();
      }
    }
    for (let sckt of multiplesSockets) {
      if (sckt) {
        sckt.onclose = () => {
          if (!noDataInConsole) {
            console.log({ event: "close" });
          }
        };
        sckt.close();
      }
    }
    mediaRecords = [];
    openedSockets = [];

    resetTranscripts();
  };

  useEffect(() => {
    if (multiplesTranscript.length === 0) return;

    handleMatchingVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplesTranscript, finalTranscript]);
  const activateMicrophone = (canListen = true) => {
    if (!canListen || isListening) {
      return;
    }

    if (!startPlayingVideos || openedSockets.length > 0) return;
    setIsMicGettingInitialise(true);
    timerService.stopTimer();
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      for (let el of multiplesProjectLanguages) {
        if (el === "") continue;
        let mediaRecord = `mediaRecorder${el}`;
        let socket = `socket${el}`;

        handleSocket(mediaRecord, stream, el, socket);
      }
      isListening = true;
      setMultiplesSockets(openedSockets);
      setMultiplesMediarecords(mediaRecords);
    });
  };
  function handleSocket(mediaRecorder, stream, lang, socket) {
    try {
      mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
    } catch (err1) {
      try {
        mediaRecorder = new MediaRecorder(stream, { mimeType: "video/mp4" });
      } catch (err2) {
        console.error({ err1 });
        console.error({ err2 });
      }
    }

    socket = new WebSocket(
      llmModelName && llmModelName !== ""
        ? `${process.env.REACT_APP_DEEPGRAMM_LIVE_URL}?model=nova-2&language=${lang}&endpointing=200&punctuate=true`
        : `${process.env.REACT_APP_DEEPGRAMM_LIVE_URL}?model=nova-2&language=${lang}&endpointing=200`,
      ["token", process.env.REACT_APP_DEEPGRAMM_API_KEY]
    );
    socket.onopen = () => {
      if (!noDataInConsole) {
        console.log({ event: "start listening", lang });
      }

      mediaRecorder.addEventListener("dataavailable", async (event) => {
        if (event.data.size > 0 && socket.readyState === 1) {
          socket.send(event.data);
        }
      });
      mediaRecorder.start(1000);
    };
    openedSockets.push(socket);
    mediaRecords.push(mediaRecorder);

    timerService.start(outroTimeOut);
    socket.onmessage = (message) => {
      const received = JSON.parse(message.data);
      let transcript = savedTranscript;
      if (received && received.channel && received.channel.alternatives) {
        transcript = transcript.concat(
          received.channel.alternatives[0].transcript
        );
      }
      stopMicAndPlayOutroAfterSomeTime(mediaRecorder, socket);
      setSavedTranscript((transc) => transc.concat(transcript));
      if (
        transcript &&
        transcript !== "" &&
        received &&
        received.is_final &&
        !isChoosenVideoPlaying
      ) {
        setSavedTranscript("");
        setFinalTranscript(transcript);
        transcriptList.push({
          confidence: received.channel.alternatives[0].confidence,
          transcript: transcript,
          lang: lang,
        });
        setMultiplesTranscript(transcriptList);
      }
    };
    socket.onerror = (error) => {
      if (!noDataInConsole) {
        console.log({ event: "onerror", error });
      }
    };
  }

  function stopMicAndPlayOutroAfterSomeTime(mediaRecord, sock) {
    /*  if(timerService.timeToReload){
      window.location.reload();
    } */
    if (timerService.playOutroVideo) {
      stopMicrophone();
      playRandomOutroVideo();
    }
  }
  function playRandomOutroVideo() {
    timerService.playOutroVideo = false
    let randomIndex = Math.floor(Math.random() * outroVideos.length);
    const randOutroVideo = outroVideos[randomIndex];
    if (!randOutroVideo) {
      window.location.reload();
      return;
    }
    handleCurrentVideo(randOutroVideo);
    setIntroVideoIndex(0);
  }

  async function getNextIntroVideo() {
    let randomIndex = Math.floor(Math.random() * introVideos.length);
    handleCurrentVideo(introVideos[randomIndex]);
    setIntroVideoIsPlaying(true);
    setIntroVideoIndex((currentIntroIndex) => currentIntroIndex + 1);
  }
  const handleMatchingVideo = async () => {
    multiplesTranscript.sort(function (a, b) {
      return b.confidence - a.confidence;
    });
    if (isChoosenVideoPlaying) {
      setMultiplesTranscript([]);
      setFinalTranscript("");
      transcriptList = [];
      return;
    }

    if (multiplesTranscript.length > 0 && multiplesTranscript[0].transcript) {
      setFinalTranscript(multiplesTranscript[0].transcript);
    }
    let isBlacklisted = isWordBlacklisted();
    if (isBlacklisted || finalTranscript === "") {
      setSavedTranscript("");
      setFinalTranscript("");

      return;
    }

    if (!noDataInConsole) {
      for (let trscpt of multiplesTranscript) {
        console.log(trscpt);
      }
    }
    if (timerService.firstTranscript === "")
      timerService.firstTranscript = finalTranscript;

    timerService.tempTranscript = finalTranscript;
    stopMicrophone();
    timeout(500).then(() => {
      if (llmModelName && llmModelName !== "") {
        findMatchingVideoWithApi();
      } else {
        findMatchingVideoWithKeywords();
      }
    });
  };
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  function playRandomFilterVideo(filterName) {
    let specificFilterList = filterVideos.filter((video) =>
      video.keywords.toLowerCase().includes(filterName.toLowerCase())
    );
    if (specificFilterList && specificFilterList.length > 0) {
      let randomIndex = Math.floor(Math.random() * specificFilterList.length);
      const randFilterVideo = specificFilterList[randomIndex];
      if (randFilterVideo) {
        handleCurrentVideo(randFilterVideo);
        setIndex();
        return;
      }
    }
  }
  const findMatchingVideoWithApi = async () => {
    let body = {
      question: multiplesTranscript[0].transcript,
      language: multiplesTranscript[0].lang,
    };
    if (deepsetFilter !== "" && deepsetFilter !== null && deepsetFilter !== 'UNSURE' ) {
      body.filter = deepsetFilter;
    }
    const res = await llmService.getVideo(projectId, body);
    if (res !== "" && res.filter) {
      setDeepsetFilter(res.filter);
      playRandomFilterVideo(res.filter);
    }

    if (!noDataInConsole) {
      console.log(body, "body", res, "response");
      console.log(res.videoId);
    }

    const cr_video = allVideos.filter((video) => video.id === res.videoId);
    if (cr_video && cr_video.length > 0) {
      handleCurrentVideo(cr_video[0]);
      setIndex();
      return;
    } else {
      getCurrentVideoFromRepeatVideos();
      setFinalTranscript("");
    }
    activateMicrophone(canListen);
  };
  function resetTranscripts() {
    setFinalTranscript("");
    setMultiplesTranscript([]);

    transcriptList = [];
  }
  const getCurrentVideoFromRepeatVideos = () => {
    setCountNotFoundedVideos((val) => val + 1);
    if (
      repeatVideos.length > 0 &&
      countNotFoundedVideos ===
        parseInt(process.env.REACT_APP_REPEAT_AFTER_NUMBER_REQUEST)
    ) {
      setCountNotFoundedVideos(0);
      let randomIndex = Math.floor(Math.random() * repeatVideos.length);
      handleCurrentVideo(repeatVideos[randomIndex]);
      setIndex();
      return;

    }
  };
  useEffect(
    () => {
      if (!hasInternet) {
        stopMicrophone();
      } else {
        if (!isListening) {
          activateMicrophone();
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasInternet]
  );
  function checkConnectify() {
    if (!navigator.onLine) {
      setHasInternet(false);
    } else {
      setHasInternet(true);
    }
  }
  const getCurrentVideoFromIntroOrLoopVideos = (reset) => {
    if (!currentVideo.isLoopVideo && !isListening) {
      activateMicrophone(canListen);
    }
    if (startPlayingVideos === false) {
      setStartPlayingVideos(true);
    }
    if (
      currentVideo &&
      currentVideo.keywords &&
      currentVideo.keywords.toLowerCase().includes("outro:")
    ) {
      window.location.reload();
      return;
    }
    if (reset === true) {
      setIntroVideoIndex(0);
    }
    const pickCurrentVideoFromIntroVideos =
      introVideos.length > 0 && introVideoIndex < 1;

    if (pickCurrentVideoFromIntroVideos) {
      getNextIntroVideo();
    } else {
      pickVideo(loopVideos);
    }

    setIndex();
  };

  function setIndex() {
    if (introVideoIsPlaying) {
      setIntroVideoIsPlaying(false);
    }

    setKeyIndex((incInd) => incInd + 1);
  }
  function isWordBlacklisted() {
    blacklist.forEach((data) => {
      if (data === finalTranscript) {
        return true;
      }
    });
    return false;
  }
  const evaluteLogicalExpression = (expression) => {
    expression = expression.replaceAll('"', "").replaceAll(" ", "");
    return _evaluateExpression(expression);
  };
  const findMatchingVideoWithKeywords = () => {
    for (const video of allVideos) {
      if (!video.keywords) continue;
      if (evaluteLogicalExpression(video.keywords)) {
        handleCurrentVideo(video);
        setIndex();

        return;
      }
    }

    getCurrentVideoFromRepeatVideos();
    activateMicrophone(canListen);
  };

  const _evaluateExpression = (expression) => {
    if (expression.includes("(") && expression.includes(")")) {
      const openParenIndex = expression.lastIndexOf("(");
      const closeParenIndex = expression.indexOf(")", openParenIndex);

      const subExpression = expression.substring(
        openParenIndex + 1,
        closeParenIndex
      );
      const subExpressionResult = _evaluateExpression(subExpression);

      const newExpression = expression.replaceRange(
        openParenIndex,
        closeParenIndex + 1,
        subExpressionResult.toString().toUpperCase()
      );
      return _evaluateExpression(newExpression);
    } else if (expression.includes("OR")) {
      const parts = expression.split("OR");
      return parts.some(_evaluateExpression);
    } else if (expression.includes("AND")) {
      const parts = expression.split("AND");
      return parts.every(_evaluateExpression);
    } else if (expression.startsWith("NOT")) {
      const subExpression = expression.substring(3);
      return !_evaluateExpression(subExpression);
    } else if (expression === "TRUE") {
      return true;
    } else if (expression === "FALSE") {
      return false;
    } else {
      const val = finalTranscript.toString().replaceAll(" ", "");

      return val.includes(expression.toLowerCase());
    }
  };

  function pickVideo(arr) {
    if (arr.length === 0 || arr == null || arr === undefined) {
      return null;
    }
    const totals = [];
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      total += arr[i].weight;
      totals.push(total);
    }
    const rnd = Math.floor(Math.random() * total);
    let selectedVideo = arr[0];
    for (let i = 0; i < totals.length; i++) {
      if (totals[i] > rnd) {
        selectedVideo = arr[i];
        break;
      }
    }

    handleCurrentVideo(selectedVideo);

    return selectedVideo;
  }

  return (
    <AppContext.Provider
      value={{
        isMicGettingInitialise,
        activateMicrophone,
        keyIndex,
        stopMicrophone,
        introVideos,
        loopVideos,
        handleCurrentVideo,
        allVideos,
        initDatas,
        finalTranscript,
        startPlayingVideos,
        defaultVideo,
        currentVideo,
        introVideoIsPlaying,
        getCurrentVideoFromIntroOrLoopVideos,
        areAllVideosDownloaded,
        canListen,
        retrieveOrDowloadVideos,
        isChoosenVideoPlaying,
        isChoosenVideoFinishingPlaying,
        handleCanListen,
        handleDataInConsole,
        horizontalAdjustment,
        verticalAdjustment,
        checkConnectify,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useVideoStateContext() {
  return useContext(AppContext);
}
