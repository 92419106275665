import { useVideoStateContext } from "../context/videoContext";
import { fetchVideos } from "../api/api";
import { ERRORS } from "../api/constants/constants";
import { useEffect, useState } from "react";
import ProjectVideos from "./ProjectVideos";
import { useParams } from "react-router-dom";

const Home = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);

  const { id } = useParams();
  const {
    retrieveOrDowloadVideos,
    areAllVideosDownloaded,
  } = useVideoStateContext();
  function checkIfDownloadParameterExist() {
    let url = new URL(window.location.href);
    if (url.searchParams.has("download")) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    const retrieveVideos = async () => {    
      if (!id) return null;
      if(!areAllVideosDownloaded) return;
     
      try {
        let shouldLoadVideos = checkIfDownloadParameterExist()
        var response = await fetchVideos(`Video/project/${id}`);
        if (response.data == null) {
          ERRORS.forEach((code) => {
            if (code.code === response) {
              setErrorMessage(code.message);
              return;
            }
          });
          return;
        } else {
          
          retrieveOrDowloadVideos(response.data.videos, shouldLoadVideos);
          setData(response.data)
        }
      } catch (error) {
        console.log(error);
      }
    };
    retrieveVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllVideosDownloaded]);
  return errorMessage !== "" ? (
    <h1 className="fullscreen  items-center p-9 text-center bg-white">
      {errorMessage}
    </h1>
  ) : (
    areAllVideosDownloaded ? <div className="text-center text-sm">please wait, data are loading </div>: <ProjectVideos data={data} />
  );
};

export default Home;
