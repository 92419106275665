import React, { useEffect, useState } from 'react'
import ImageComp from './ImageComp';

const StartImage = ({base64Image}) => {
    const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        if(base64Image){
            const blob = bas64ToBlob(base64Image)
            const file = blobToFile(blob)
            const url = URL.createObjectURL(file)
            setImageUrl(url)
            

        }

    }, [base64Image])

    const bas64ToBlob = (base64) => {
        const bytesCharacters = atob(base64)
        const byteNumbers = Array(bytesCharacters.length)
        for(let i = 0; i< bytesCharacters.length; i++){
            byteNumbers[i] = bytesCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        return new Blob([byteArray], {type: 'image/jpeg'})
    }
    const blobToFile = (blob) => {
        return  new File([blob], 'image.jpg', {type: 'image/jpeg'})
    }
  return (
    <ImageComp url={imageUrl}/>
   
  )
}

export default StartImage