/* function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
} */
const VideoComp = ({videoRef,classname, index, onEnd,srcUrl,autoPlay, mute, horizontalAdjustment, verticalAdjustment}) => {


  return (
    horizontalAdjustment !== 0 || verticalAdjustment !== 0 ?<video
    ref={videoRef}
    className="fullscreen  "
    style={{  height:`100%`, position:"absolute" , left:`${horizontalAdjustment}%`, top:`${verticalAdjustment}%`}}
    key={index}
    playsInline
    onEnded={onEnd}
    src={srcUrl}
    autoPlay={autoPlay}
    muted={mute}
  ></video>: <video
    ref={videoRef}
    className={classname ?? null}
    key={index}
    width="100%"
    playsInline
    height="100%"
    onEnded={onEnd}
    src={srcUrl}
    autoPlay={autoPlay}
    muted={mute}
  ></video>
  )
}

export default VideoComp